<template>
  <v-card style="box-shadow: none">
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Alterar E-mail</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-row justify="center">
      <v-col
        lg="6"
        md="8"
        sm="8"
      >
        <v-stepper v-model="stepIndex">
          <v-stepper-header>
            <v-stepper-step
              :complete="stepIndex > 1"
              step="1"
            >
              Buscar Cadastro
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="stepIndex > 2"
              step="2"
            >
              Alterar E-mail Principal
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card style="box-shadow: none">
                <v-form
                  ref="formUserEmail"
                  style="border: 0px solid transparent"
                  @submit.prevent="getUserEmailByCpf"
                >
                  <v-row
                    justify="start"
                    no-gutters
                    class="pa-2 mt-0"
                  >
                    <v-col
                      class="d-flex pr-5"
                      lg="6"
                      md="6"
                      sm="6"
                    >
                      <v-text-field
                        v-model="user.cpf"
                        outlined
                        dense
                        label="CPF"
                        :rules="$rules.cpf"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    justify="start"
                    no-gutters
                    class="pa-2 mt-0"
                  >
                    <v-col
                      class="d-flex pr-5"
                      lg="6"
                      md="12"
                      sm="12"
                    >
                      <v-btn
                        color="primary"
                        type="submit"
                      >
                        Buscar Cadastro
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card style="box-shadow: none">
                <v-form
                  ref="formUser"
                  style="border: 0px solid transparent"
                  @submit.prevent="changeEmail"
                >
                  <v-row
                    justify="start"
                    no-gutters
                    class="pa-2 mt-0"
                  >
                    <v-col
                      class="d-flex pr-5"
                      lg="6"
                      md="6"
                      sm="6"
                    >
                      <v-text-field
                        v-model="user.email"
                        outlined
                        dense
                        label="E-mail"
                        :rules="$rules.email"
                        required
                      />
                    </v-col>
                    <v-col
                      class="d-flex align-center pr-5"
                      style="color: #1976d2"
                      lg="6"
                      md="6"
                      sm="6"
                    >
                      <p class="text-left">
                        Insira o novo e-mail.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="start"
                    no-gutters
                    class="pa-2 mt-0"
                  >
                    <v-col
                      class="d-flex pr-5"
                      lg="6"
                      md="12"
                      sm="12"
                    >
                      <v-btn
                        color="primary"
                        type="submit"
                      >
                        Alterar e-mail
                      </v-btn>
                    </v-col>
                    <v-col
                      class="d-flex pr-5"
                      lg="4"
                      md="4"
                      sm="4"
                    >
                      <v-btn
                        color="secondary"
                        @click="stepIndex = 1"
                      >
                        Voltar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';
import ChangeEmailService from '@/services/change-email.service';
import ChangePasswordService from '@/services/change-password.service';

export default {
  name: 'ChangeEmailForm',
  directives: { mask },
  data() {
    return {
      stepIndex: 1,
      user: {
        email: '',
        cpf: '',
      },
      changedEmail: {},
    };
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async changeEmail() {
      if (!this.$refs.formUser.validate()) {
        this.$toast.error('Verifique os campos, por favor!');
        return;
      }
      try {
        this.$emit('email-changed');
        await ChangeEmailService.changeEmail(this.user);
        this.$toast.success('E-mail alterado com sucesso');
        this.$refs.formUserEmail.reset();
        this.$refs.formUserEmail.resetValidation();
        this.stepIndex = 1;
      } catch (e) {
        this.$toast.error('Verifique os campos e tente novamente');
        this.$handleHttpError(e);
      }
    },
    async getUserEmailByCpf() {
      if (!this.$refs.formUserEmail.validate()) {
        this.$toast.error('Verifique os campos, por favor!');
        return;
      }
      try {
        const result = await ChangePasswordService.getUserEmailByCpf(
          this.user.cpf,
        );
        this.user.email = result.email;
        this.stepIndex = 2;
      } catch (e) {
        this.$toast.error('Verifique os campos, por favor!');
        this.$handleHttpError(e);
      }
    },
  },
};
</script>
